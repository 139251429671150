<template>
  <Accordion
    class="mb-6"
    :title="title"
    :icon="IconComponent"
    title-class-name="font-medium font-primary text-gray-800 text-[18px]"
  >
    <slot />
  </Accordion>
</template>

<script lang="ts" setup>
import Accordion from '@cypress-design/vue-accordion'
import { computed, h } from 'vue'
import { IconActionQuestionMarkCircle } from '@cypress-design/vue-icon'

const props = defineProps<{
  title: string
}>()

const IconComponent = computed(() => () => {
  return h(IconActionQuestionMarkCircle, {
    fillColor: 'gray-100',
    strokeColor: 'gray-500',
  })
})
</script>
